:root {
  --section-rotate: 9vw;
  --section-rotate-mobile: 9vw;
}

::-moz-selection {
  background-color: #55c57a;
  color: #fff;
}

::selection {
  background-color: #55c57a;
  color: #fff;
}

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  -webkit-box-sizing: inherit;
  box-sizing: inherit;
}

html {
  font-size: 62.5%;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

body {
  line-height: 1.6;
  font-weight: 300;
  font-family: 'Lato', sans-serif;
  color: #777;
  min-height: 100vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

ul {
  list-style-type: none;
  cursor: pointer;
}

.flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.container {
  max-width: 120rem;
  margin: 0 auto;
}

.ma-bt-md {
  margin-bottom: 3rem !important;
}

.ma-bt-lg {
  margin-bottom: 3.5rem !important;
}

.heading-secondary {
  font-size: 2.25rem;
  text-transform: uppercase;
  font-weight: 700;
  background-image: -webkit-gradient(linear,
      left top,
      right top,
      from(#7dd56f),
      to(#28b487));
  background-image: linear-gradient(to right, #929292, #4a4a4a);
  -webkit-background-clip: text;
  color: transparent;
  letter-spacing: 0.1rem;
  line-height: 1.3;
  display: inline-block;
}

.contact-heading {
  font-size: 2.25rem;
  text-transform: uppercase;
  font-weight: 700;
  background-image: linear-gradient(to right, #929292, #4a4a4a);
  -webkit-background-clip: text;
  color: transparent;
  letter-spacing: 0.1rem;
  line-height: 1.3;
}

@media only screen and (max-width: 50em) {
  .heading-secondary {
    font-size: 1.7rem;
  }
}

.heading-realizations {
  font-size: 3rem;
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
  color: #444;
  line-height: 1.3;
  display: inline-block;
}

@media only screen and (max-width: 50em) {
  .heading-realizations {
    font-size: 2.5rem;
  }
}

.heading-secondary--error {
  background-image: -webkit-gradient(linear,
      left top,
      right top,
      from(#ff7730),
      to(#eb4d4b));
  background-image: linear-gradient(to right, #ff7730, #eb4d4b);
  font-size: 3.5rem;
}

.heading-primary {
  color: #fff;
  text-transform: uppercase;
  font-weight: 300;
}

.heading-tertiary {
  font-size: 3em;
  text-align: center;
  width: 70%;
  margin: 0 auto;
  line-height: 1.7;
  font-weight: 600;
  text-transform: uppercase;
  color: black;
}

.heading-primary span {
  padding: 1rem 1.5rem;
  line-height: 1.7;
  -webkit-box-decoration-break: clone;
  box-decoration-break: clone;
  background-image: -webkit-gradient(linear,
      left top,
      right bottom,
      from(rgba(74, 74, 74, 0.85)),
      to(rgba(222, 222, 222, 0.85)));
  background-image: linear-gradient(to bottom right, 
   rgba(74, 74, 74, 0.85),
   rgba(222, 222, 222, 0.85));
}

.heading-primary {
  font-size: 2.75vw;
  text-align: center;
  width: 70%;
  margin: 0 auto;
}

@media only screen and (max-width: 30em) {
  .heading-primary, .heading-tertiary {
    line-height: 3;
  }
}

@media only screen and (max-width: 50em) and (min-width: 30em) {
  .heading-primary, .heading-tertiary {
    line-height: 2.5;
  }
}

@media only screen and (max-width: 75em) and (min-width: 50em) {
  .heading-primary, .heading-tertiary {
    line-height: 1.95;
  }
}



.btn,
.btn:link,
.btn:visited {
  font-size: 1.6rem;
  padding: 1.4rem 3rem;
  border-radius: 10rem;
  text-transform: uppercase;
  display: inline-block;
  text-decoration: none;
  position: relative;
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
  font-weight: 400;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  /*Add later when we use this for the button in form*/
  border: none;
  cursor: pointer;
}

@media only screen and (max-width: 50em) {
  .btn {
    font-size: 1.25rem;
  }
}

.btn:hover {
  -webkit-transform: translateY(-3px);
  transform: translateY(-3px);
  -webkit-box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.15);
  box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.15);
}

.btn:active {
  -webkit-transform: translateY(-1px);
  transform: translateY(-1px);
  -webkit-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
}

.btn:focus {
  outline: none;
  background-color: #2e864b;
}

.btn--white {
  background-color: #fff;
  color: #777;
}

.btn--white::after {
  background-color: #fff;
}

.btn--green {
  background-color: #55c57a;
  color: #fff;
}

.btn--green::after {
  background-color: #55c57a;
}

.btn--small {
  padding: 1.25rem 3rem !important;
  font-size: 1.4rem !important;
}

.btn-small,
.btn-small:link,
.btn-small:visited {
  background-color: #55c57a;
  color: #fff;
  font-size: 1.4rem;
  padding: 1.25rem 3rem;
  border-radius: 10rem;
  text-transform: uppercase;
  position: relative;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
  cursor: pointer;
  text-decoration: none;
  font-weight: 400;
  border: none;
}

.btn-small:hover {
  background-color: #7dd56f;
}

.btn-text:link,
.btn-text:visited {
  color: #55c57a;
  display: inline-block;
  text-decoration: none;
  border-bottom: 1px solid #55c57a;
  padding: 3px;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
}

.btn-text:hover {
  background-color: #55c57a;
  color: #fff;
  -webkit-box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.15);
  box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.15);
  -webkit-transform: translateY(-2px);
  transform: translateY(-2px);
}

.btn-text:active {
  -webkit-transform: translateY(0);
  transform: translateY(0);
  -webkit-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
}

.btn-text:focus {
  outline: none;
  outline: 3px solid #55c57a;
  outline-offset: 3px;
}

header {
  /* background-color: transparent !important; */
  /* background-image: -webkit-gradient(
    linear,
    left top,
    right bottom,
    from(rgba(125, 213, 111, 0.85)),
    to(rgba(40, 180, 135, 0.85))
  );
  background-image: linear-gradient(
    to bottom right,
    rgba(125, 213, 111, 0.85),
    rgba(40, 180, 135, 0.85)
  );
  opacity: 0.5 */
}

.header {
  background-color: #444;
  padding: 0 5rem;
  height: 8rem;
  position: relative;
  z-index: 100;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

@media only screen and (max-width: 62.5em) {
  .header {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }
}

.header__logo img {
  height: 3.5rem;
}

@media only screen and (max-width: 62.5em) {
  .header__logo {
    -webkit-box-ordinal-group: 0;
    -ms-flex-order: -1;
    order: -1;
    margin-bottom: 1.5rem;
  }
}

.navbar {
  line-height: 1;
  background-color: rgba(40, 180, 135, 0.5) !important;
  /* z-index: 999; */
  /* background-image: -webkit-gradient(
    linear,
    left top,
    right bottom,
    from(rgba(125, 213, 111, 0.85)),
    to(rgba(40, 180, 135, 0.85))
  );
  background-image: linear-gradient(
    to bottom right,
    rgba(125, 213, 111, 0.85),
    rgba(40, 180, 135, 0.85)
  );
  opacity: 0.5 */
}

.navbar::before {
  /* background-size: cover;
  content: "";
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  opacity: 0.3;
  -webkit-box-decoration-break: clone;
  box-decoration-break: clone; */
  /* background-image: -webkit-gradient(
    linear,
    left top,
    right bottom,
    from(rgba(125, 213, 111, 0.85)),
    to(rgba(40, 180, 135, 0.85))
  );
  background-image: linear-gradient(
    to bottom right,
    rgba(125, 213, 111, 0.85),
    rgba(40, 180, 135, 0.85)
  ); */
}

.section-header {
  /* margin-top: 56px; */
  position: relative;
  height: 70vh;
  /* clip-path: polygon(
    0 0,
    100% 0,
    100% calc(100% - var(--section-rotate)),
    0 100%
  );
  -webkit-clip-path: polygon(
    0 0,
    100% 0,
    100% calc(100% - var(--section-rotate)),
    0 100%
  ); */
}

.header__hero {
  height: 100%;
  overflow: hidden;
}

.header__hero-img--2 {
  /* -o-object-fit: cover; */
  object-fit: cover;
  height: 100vh;
  width: auto;
  /* -o-object-position: 50% 25%; */
  object-position: 50% 75%;
}


.header__hero-img {
  /* -o-object-fit: cover; */
  object-fit: cover;
  height: 100%;
  width: 100%;
  /* -o-object-position: 50% 25%; */
  object-position: 50% 75%;
}

@media screen and (max-width: 300px) {
  .header__hero-img, .header__hero-img--2 {
    object-position: 50% 80%;
  }
}


.header__hero-img-gallery {
  -o-object-fit: cover;
  object-fit: cover;
  height: 100%;
  width: 100%;
  -o-object-position: 50% 80%;
  object-position: 50% 80%;
}

.header__hero-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  background-image: -webkit-gradient(linear,
      left top,
      right bottom,
      from(#7dd56f),
      to(#28b487));
  background-image: linear-gradient(to right bottom, #7dd56f, #28b487);
  opacity: 0.3;
}

.header__hero-overlay-gallery {
  position: absolute;
  width: 100%;
  height: 100vh;
  background-image: -webkit-gradient(linear,
      left top,
      right bottom,
      from(#7dd56f),
      to(#28b487));
  background-image: linear-gradient(to right bottom, #929292, #ebebeb);
  opacity: 0.8;
}

.heading-box {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.heading-box-gallery {
  position: absolute;
  width: 50%;
  height: auto;
  max-height: 50%;
  top: 50%;
  left: 25%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.heading-box-gallery-img {
  position: absolute;
  top: 50%;
  left: 75%;
  width: 50%;
  height: auto;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.down-arrows {
  position: absolute;
  top: 95%;
  left: 25%;
}

.down-arrows {
  cursor: pointer;
}

.arrows {
  stroke: white;
  width: 60px;
  height: 72px;
  position: absolute;
  transition: 0.3s;
  margin-left: -30px;
  bottom: 20px;
}

.arrows:hover {
  stroke: #444;
}

.arrows path {
  fill: transparent;
  stroke-width: 3px;
  animation: arrow 2s infinite;
  -webkit-animation: arrow 2s infinite;
}

@keyframes arrow {
  0% {
    opacity: 0
  }

  40% {
    opacity: 1
  }

  80% {
    opacity: 0
  }

  100% {
    opacity: 0
  }
}

@-webkit-keyframes arrow

/*Safari and Chrome*/
  {
  0% {
    opacity: 0
  }

  40% {
    opacity: 1
  }

  80% {
    opacity: 0
  }

  100% {
    opacity: 0
  }
}

.arrows path.a1 {
  animation-delay: -1s;
  -webkit-animation-delay: -1s;
  /* Safari 和 Chrome */
}

.arrows path.a2 {
  animation-delay: -0.5s;
  -webkit-animation-delay: -0.5s;
  /* Safari 和 Chrome */
}

.arrows path.a3 {
  animation-delay: 0s;
  -webkit-animation-delay: 0s;
  /* Safari 和 Chrome */
}

@media only screen and (max-width: 62.5em) {
  .down-arrows {
    left: 50%;
    top: 90%;
    height: 72px;
  }

  .heading-box-gallery {
    position: absolute;
    width: 100%;
    height: auto;
    max-height: 50%;
    top: 75%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }

  .heading-box-gallery-img {
    position: absolute;
    text-align: center;
    top: 35%;
    left: 50%;
    width: 80%;
    height: auto;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }
}



@media only screen and (max-width: 62.5em) {
  .heading-box-gallery {
    top: 50%;
  }

  .heading-box-gallery-img {
    display: none;
  }
}

.heading-img {
  -o-object-fit: cover;
  object-fit: cover;
  height: auto;
  width: 90%;
  -o-object-position: 50% 80%;
  object-position: 50% 80%;
}

.heading-box__group {
  color: #f7f7f7;
  margin-top: 2.2vw;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.heading-box__group--2 {
  color: black;
  margin-top: 2.2vw;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.heading-box__detail {
  font-size: 1.5rem;
  font-weight: 700;
  text-transform: uppercase;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  text-shadow: 0 0.5rem 2rem rgba(0, 0, 0, 0.15);
}

.heading-box__detail-gallery {
  font-size: 1.75em;
  font-weight: 700;
  width: 80%;
  display: flex;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  text-shadow: 0 0.5rem 2rem rgba(0, 0, 0, 0.15);
}

.heading-box__detail-gallery-2 {
  font-size: 20px;
  font-weight: 700;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  text-shadow: 0 0.5rem 2rem rgba(0, 0, 0, 0.15);
}

@media only screen and (max-width: 60em) {
  .heading-box__detail {
    font-size: 0.9rem;
  }
}

.heading-box__detail svg {
  margin-right: 0.8rem;
}

.heading-box__detail:not(:last-child) {
  margin-right: 4rem;
}

.heading-box__icon {
  height: 2rem;
  width: 2rem;
  fill: currentColor;
  -webkit-filter: drop-shadow(0 0.75rem 0.5rem rgba(0, 0, 0, 0.25));
  filter: drop-shadow(0 0.75rem 0.5rem rgba(0, 0, 0, 0.25));
}

.section-description {
  background-color: #fcfcfc;
  margin-top: calc(0px - var(--section-rotate));
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.section-description>* {
  padding: 0 8vw;
  padding-top: 14vw;
  padding-bottom: calc(1vw + var(--section-rotate));
  -webkit-box-flex: 0;
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
}

@media only screen and (max-width: 50em) {
  .section-description {
    margin-top: 0;
    flex-direction: column;
  }

  .section-description>* {
    padding-top: 10vw;
  }
}



.description-box .description {
  margin-right: 5rem;
}

.description-box .description__text {
  font-size: 1.7rem;
}

.description-box .description__text:not(:last-child) {
  margin-bottom: 2rem;
}

.overview-box {
  background-color: #f7f7f7;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.overview-box__group:not(:last-child) {
  margin-bottom: 7rem;
}

.overview-box__detail {
  font-size: 1.5rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-weight: 400;
}

.overview-box__detail svg {
  margin-right: 1.25rem;
}

.overview-box__detail:not(:last-child) {
  margin-bottom: 2.25rem;
}

.overview-box__icon {
  height: 2.25rem;
  width: 2.25rem;
  fill: #55c57a;
}

.navbar-icon {
  font-size: 60em;
  background-image: linear-gradient(to right bottom, #7dd56f, #28b487);
  -webkit-background-clip: 'text';
}

.overview-box__label {
  font-weight: 700;
  margin-right: 2.25rem;
  text-transform: uppercase;
  font-size: 1.4rem;
}

.overview-box__img {
  height: 12rem;
  border-radius: 0.5rem;
  width: auto;
  margin-right: 1.25rem;
}

.section-pictures {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  clip-path: polygon(0 var(--section-rotate),
      100% 0,
      100% calc(100% - var(--section-rotate)),
      0 100%);
  -webkit-clip-path: polygon(0 var(--section-rotate),
      100% 0,
      100% calc(100% - var(--section-rotate)),
      0 100%);
  margin-top: calc(0px - var(--section-rotate));
  position: relative;
  z-index: 1000;
}

.picture-box__img {
  display: block;
  width: 100%;
  height: 110%;
  -o-object-fit: cover;
  object-fit: cover;
}

.picture-box__img--1 {
  padding-top: 15%;
}

.picture-box__img--2 {
  padding-bottom: 15%;
}

.picture-box__img--3 {
  padding-bottom: 27%;
}

@media only screen and (max-width: 40em) {

  .picture-box__img--1 {
    padding-top: 0%;
  }

  .picture-box__img--2 {
    display: none;
  }

  .picture-box__img--3 {
    display: none;
  }
}

.section-reviews {
  margin-top: calc(0px - var(--section-rotate));
  padding: calc(5rem + var(--section-rotate)) 0;
  position: relative;
  z-index: 1000;
  background: -webkit-gradient(linear,
      left top,
      right bottom,
      from(#7dd56f),
      to(#28b487));
  background: linear-gradient(to right bottom, #dedede, #929292);
  clip-path: polygon(0 var(--section-rotate),
      100% 0,
      100% calc(100% - var(--section-rotate)),
      0 100%);
  -webkit-clip-path: polygon(0 var(--section-rotate),
      100% 0,
      100% calc(100% - var(--section-rotate)),
      0 100%);
}

.reviews {
  padding: 5rem 0;
  display: grid;
  grid-column-gap: 6rem;
  grid-auto-flow: column;
  overflow-x: scroll;
  -ms-scroll-snap-type: x mandatory;
  scroll-snap-type: x mandatory;
}

.reviews::-webkit-scrollbar {
  height: 12px;
}

.reviews::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
}

.reviews::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
  background-color: #f7f7f7;
}

.card-container {
  display: flex;
}

.card {
  border-radius: 3px;
  overflow: hidden;
  -webkit-box-shadow: 0 1.5rem 4rem rgba(0, 0, 0, 0.1);
  box-shadow: 0 1.5rem 4rem rgba(0, 0, 0, 0.1);
  background-color: #fff;
  -webkit-transition: 0.3s all;
  transition: 0.3s all;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  position: 'relative';
}

.reviews__card {
  width: 30rem;
  padding: 4rem;
  background-color: #f7f7f7;
  border-radius: 3px;
  -webkit-box-shadow: 0 1.5rem 4rem rgba(0, 0, 0, 0.15);
  box-shadow: 0 1.5rem 4rem rgba(0, 0, 0, 0.15);
  scroll-snap-align: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.reviews:before,
.reviews:after {
  content: '';
  width: 2rem;
}

.reviews__avatar {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 2rem;
}

.reviews__avatar-img {
  height: 4.5rem;
  border-radius: 50%;
  margin-right: 1.5rem;
}

.reviews__user {
  font-size: 1.5rem;
  font-weight: 700;
  text-transform: uppercase;
}

.reviews__text {
  font-size: 1.5rem;
  margin-bottom: 2rem;
  font-style: italic;
  text-align: center;
  font-weight: 400;
}

.reviews__rating {
  margin-top: auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.reviews__star {
  height: 2rem;
  width: 2rem;
  margin-right: 1px;
}

.reviews__star--active {
  fill: #55c57a;
}

.reviews__star--inactive {
  fill: #bbb;
}

.section-cta {
  margin-top: calc(0px - var(--section-rotate));
  padding: 3rem;
  padding-bottom: 11rem;
  padding-top: calc(15rem + var(--section-rotate));
  background-color: #f7f7f7;
}

.span-all-rows {
  grid-row: 1 / -1;
}

.cta {
  position: relative;
  max-width: 105rem;
  margin: 0 auto;
  overflow: hidden;
  background-color: #fff;
  padding: 9rem 5rem 9rem 21rem;
  border-radius: 2rem;
  -webkit-box-shadow: 0 3rem 8rem 0.5rem rgba(0, 0, 0, 0.15);
  box-shadow: 0 3rem 8rem 0.5rem rgba(0, 0, 0, 0.15);
}

@media only screen and (max-width: 50em) {
  .cta {
    padding: 9rem 5rem 9rem 17rem;
    max-width: 60rem;
  }
}

.cta__img {
  height: 15rem;
  width: 15rem;
  position: absolute;
  left: 0;
  top: 50%;
  border-radius: 50%;
  -webkit-box-shadow: 1rem 0.5rem 3rem rgba(0, 0, 0, 0.15);
  box-shadow: 1rem 0.5rem 3rem rgba(0, 0, 0, 0.15);
}

.cta__img--contact-form {
  height: 7rem;
  width: 7rem;
  border-radius: 50%;
  -webkit-box-shadow: 1rem 0.5rem 3rem rgba(0, 0, 0, 0.15);
  box-shadow: 1rem 0.5rem 3rem rgba(0, 0, 0, 0.15);
}

@media only screen and (max-width: 50em) {
  .cta__img {
    height: 12rem;
    width: 12rem;
  }

  .cta__img--contact-form {
    height: 6rem;
    width: 6rem;
  }
}

.cta__img--logo-contact-form {
  padding: 2rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  background: linear-gradient(to right bottom, #7dd56f, #28b487);
}

.cta__img--logo-contact-form img {
  width: 100%;
}


.cta__img--logo {
  padding: 2rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  background: linear-gradient(to right bottom, #7dd56f, #28b487);
  z-index: 10;
  -webkit-transform: translate(-35%, -50%);
  transform: translate(-35%, -50%);
}

.cta__img--navbar {
  padding: 0.5rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  background: -webkit-gradient(linear,
      left top,
      right bottom,
      from(#7dd56f),
      to(#28b487));
  background: linear-gradient(to right bottom, #7dd56f, #28b487);
  z-index: 10;
  -webkit-transform: translate(-35%, -50%);
  transform: translate(-35%, -50%);
}

.cta__img--logo img {
  width: 100%;
}

.cta__img--1 {
  -webkit-transform: translate(-10%, -50%) scale(0.97);
  transform: translate(-10%, -50%) scale(0.97);
  z-index: 9;
}

.cta__img--2 {
  -webkit-transform: translate(15%, -50%) scale(0.94);
  transform: translate(15%, -50%) scale(0.94);
  z-index: 8;
}

.cta__content {
  width: 100%;
  display: grid;
  grid-template-rows: auto auto;
  grid-template-columns: 1fr auto;
  grid-gap: 0.7rem;
  grid-auto-flow: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.cta__text {
  font-size: 1.9rem;
  font-weight: 400;
}

@media only screen and (max-width: 50em) {
  .cta__text {
    font-size: 1.1rem;
  }
}

/* SLIDER */

.sliderContainer {
  width: 100%;
  height: 100vh;
  margin: 0;
  position: relative;
  text-align: center;

  /* IN THE END */
  overflow: hidden;
}


.slider__btn {
  position: absolute;
  top: 33%;
  z-index: 10;

  border: none;
  background: transparent;
  font-family: inherit;
  color: #333;
  border-radius: 50%;
  height: 5.5rem;
  width: 5.5rem;
  font-size: 3.25rem;
  cursor: pointer;
}

.slider__btn--left {
  left: 6%;
  transform: translate(-50%, -50%);
}

.slider__btn--right {
  right: 6%;
  transform: translate(50%, -50%);
}



.dots__dot {
  border: none;
  background-color: #b9b9b9;
  opacity: 0.7;
  height: 1rem;
  width: 1rem;
  border-radius: 50%;
  margin-right: 1.75rem;
  cursor: pointer;
  transition: all 0.5s;

  /* Only necessary when overlying images */
  /* box-shadow: 0 0.6rem 1.5rem rgba(0, 0, 0, 0.7); */
}

.dots__dot:last-child {
  margin: 0;
}

.dots__dot--active {
  /* background-color: #fff; */
  background-color: #888;
  opacity: 1;
}

/* TESTIMONIALS */
.testimonial {
  width: 65%;
  position: relative;
}

.testimonial::before {
  content: '\201C';
  position: absolute;
  top: -5.7rem;
  left: -6.8rem;
  line-height: 1;
  font-size: 20rem;
  font-family: inherit;
  color: var(--color-primary);
  z-index: -1;
}

.testimonial__header {
  font-size: 2.25rem;
  font-weight: 500;
  margin-bottom: 1.5rem;
}

.testimonial__text {
  font-size: 1.7rem;
  margin-bottom: 3.5rem;
  color: #666;
}

.testimonial__author {
  margin-left: 3rem;
  font-style: normal;

  display: grid;
  grid-template-columns: 6.5rem 1fr;
  column-gap: 2rem;
}

.testimonial__photo {
  grid-row: 1 / span 2;
  width: 6.5rem;
  border-radius: 50%;
}

.testimonial__name {
  font-size: 1.7rem;
  font-weight: 500;
  align-self: end;
  margin: 0;
}

.testimonial__location {
  font-size: 1.5rem;
}

.section__title--testimonials {
  margin-bottom: 4rem;
}

.section {
  border-top: 1px solid #ddd;
  background-color: #f3f3f3;

  transition: transform 1s, opacity 1s;
}

.highlight {
  position: relative;
}

.highlight::after {
  display: block;
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: -1;
  opacity: 0.7;
  transform: scale(1.07, 1.05) skewX(-15deg);
  background-image: linear-gradient(to right, #7dd56f, #28b487);
}